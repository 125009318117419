<template>
  <div class="page">团队报表</div>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style>
</style>